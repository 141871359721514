import React from 'react';
import Layout from "../../components/Layout";
import {Container} from "@material-ui/core";
import Events from "../../components/Events";

function EventsPage(props) {
    return (
        <Layout>
          <Container maxWidth="md">
            <h1>Upcoming Events</h1>
            <Events />
          </Container>
        </Layout>
    );
}

export default EventsPage;
