export const LOCATIONS = {
  home: {
    title: 'Home',
    address: '',
    map: '',
  },
  twitch: {
    title: 'Twitch',
    address: '',
    map: 'https://www.twitch.tv/warwicksoulclub',
  },
  mixcloud: {
    title: 'Mixcloud',
    address: '',
    map: 'https://www.mixcloud.com/live/warwicksoulclub/',
  },
  'fifteen': {
    title: 'FIFTEEN',
    address: '15 Spencer St, Leamington Spa, CV31 3NE',
    map: 'https://goo.gl/maps/Meo12o24tiJTmfpu5',
  },
  'kenilworth-social-club': {
    title: 'Kenilworth Social Club',
    address: 'Montague House, Upper Rosemary Hill, Kenilworth, CV8 2PA',
    map: 'https://goo.gl/maps/1mYPyN5SA7qRPH6DA',
  },
  'the-nelson-club': {
    title: 'The Nelson Club',
    address: 'Charles St, Warwick, CV34 5LE',
    map: 'https://goo.gl/maps/89zJyL44V8aQ8S2x8',
  },
  'the-nelson-pub': {
    title: 'The Nelson Pub',
    address: '31 Emscote Rd, Warwick CV34 5QE',
    map: 'https://g.page/Thenelsonpub',
  },
  'cottage-inn': {
    title: 'Cottage Inn',
    address: '36 Stoneleigh Rd, Kenilworth, CV8 2GD',
    map: 'https://goo.gl/maps/nqoggz23Y3EkynM9A',
  },
};
