import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Event from './Event';

const StyledWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 10px;
`;

function Events() {
  const data = useStaticQuery(graphql`
    query EventsQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___date] }
        filter: {
          isFuture: { eq: true }
          frontmatter: {
            templateKey: { eq: "events-post" }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              templateKey
              title
              description
              location
              date
            }
          }
        }
      }
    }
  `);
  const { edges } = data.allMarkdownRemark;
  return (
    <StyledWrapper>
      {
        edges.map(({
          node: {
            frontmatter: {
              title, description, location, date,
            },
          },
        }) => (
          <Event
            key={date}
            title={title}
            description={description}
            location={location}
            date={new Date(date)}
          />
        ))
      }
    </StyledWrapper>
  );
}

export default Events;
