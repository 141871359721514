import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import anchorme from 'anchorme';
import { LOCATIONS } from '../utils/locations';
import logo from '../img/logo.jpg';

const StyledContent = styled.section`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 12px;
  padding: 10px;

  h1 {
    margin: 0;
    font-family: "Roboto Light", "Helvetica", "Arial", sans-serif;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .date {
    font-size: 2em;
    line-height: 1em;
  }

  .address {
    display: none;
  }
`;

interface Props {
  title: string;
  description: string;
  location: string;
  date: Date;
}

function Event(props: Props) {
  const {
    title, description, location, date, image,
  } = props;
  const formatter1 = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', weekday: 'short' });
  const formatter2 = new Intl.DateTimeFormat('en', { month: 'short' });
  const endDate = new Date(new Date(date).setHours(date.getHours() + 4));

  return (
    <Paper elevation={3}>
      <StyledContent itemScope itemType="http://schema.org/Event">
        <img itemProp="image" src={logo} alt="Warwick Soul Club Logo" style={{ display: 'none' }} />
        <div className="left">
          <div className="date">{date.getDate()}</div>
          <div>{formatter2.format(date)}</div>
          <div>{date.getFullYear()}</div>
        </div>
        <div className="right">
          <h1 itemProp="name">{title}</h1>
          <time dateTime={date.toISOString()} itemProp="startDate" content={date.toISOString()}>{formatter1.format(date)}</time>
          <time dateTime={endDate.toISOString()} itemProp="endDate" content={endDate.toISOString()} style={{ display: 'none' }}>
            {formatter1.format(endDate)}
          </time>
          <div itemProp="location" itemScope itemType="http://schema.org/Place">
            <a href={LOCATIONS[location].map} target="_blank" rel="noopener noreferrer">
              <span itemProp="name">{LOCATIONS[location].title}</span>
            </a>
            {LOCATIONS[location].address && (
              <span className="address" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                <span itemProp="streetAddress">{LOCATIONS[location].address}</span>
              </span>
            )}
          </div>
          <p
            itemProp="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: anchorme({
                input: description,
                options: {
                  attributes: {
                    target: '_blank',
                  },
                },
              }),
            }}
          />
        </div>
      </StyledContent>
    </Paper>
  );
}

export default Event;
